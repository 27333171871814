"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.concat = concat;
exports.countToString = countToString;
exports.listToString = listToString;
exports.nameStr = nameStr;
exports.nullIfEmpty = nullIfEmpty;
exports.numberOfTimes = numberOfTimes;
exports.pluralise = pluralise;
exports.verbToString = verbToString;
require("core-js/modules/es.string.trim.js");
require("core-js/modules/web.dom-collections.iterator.js");
function nameStr(user) {
  let s = '';
  if (user.firstName) s += user.firstName;
  if (user.firstName && user.lastName) s += ' ';
  if (user.lastName) s += user.lastName;
  return s;
}
function nullIfEmpty(s) {
  const trimmed = s === null || s === void 0 ? void 0 : s.trim();
  return (trimmed === null || trimmed === void 0 ? void 0 : trimmed.length) > 0 ? trimmed : null;
}
function concat(separator, args) {
  let s = '';
  for (let i = 1; i < arguments.length; i++) {
    if (arguments[i]) {
      if (s.length > 0) s += separator;
      s += arguments[i];
    }
  }
  return s;
}
const plurals = new Map([['person', {
  plural: 'people'
}]]);
function pluralise(what) {
  var _plurals$get$plural, _plurals$get;
  return (_plurals$get$plural = (_plurals$get = plurals.get(what)) === null || _plurals$get === void 0 ? void 0 : _plurals$get.plural) !== null && _plurals$get$plural !== void 0 ? _plurals$get$plural : what + 's';
}
function verbToString(what, count) {
  if (count < 2) {
    return what + 's';
  } else {
    return what;
  }
}
function countToString(what, count) {
  if (count > 1) {
    return "".concat(count, " ").concat(pluralise(what));
  } else if (count > 0) {
    return "".concat(count, " ").concat(what);
  } else {
    return "no ".concat(pluralise(what));
  }
}
function numberOfTimes(count) {
  if (count > 2) {
    return "".concat(count, " times");
  } else if (count === 2) {
    return "twice";
  } else if (count === 1) {
    return "once";
  } else {
    return "never";
  }
}
function listToString(l) {
  if (l.length > 1) return l.slice(0, l.length - 1).join(', ') + ' and ' + l[l.length - 1];else if (l.length > 0) return l[0];else return '';
}
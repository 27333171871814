const prod = (process.env.REACT_APP_IS_PRODUCTION === 'true');

export const company = {
    registeredAddress: "55, Loudoun Road, London, NW8 0DL",
    registrationNumber: "14119276",
    name: "Giving Is Human Limited",
    tradingName: "Giving Is Human",
    generalEmail: "hello@givingishuman.com",
    supportEmail: "support@givingishuman.com",
    securityEmail: "infosec@givingishuman.com",
    unsubscribeEmail: "unsubscribe@givingishuman.com",
    domains: "givingishuman.com and givingishuman.co.uk",
    facebookURL: "https://www.facebook.com/givingishuman",
    instagramURL: "https://www.instagram.com/givingishuman",
    linkedInURL: "https://www.linkedin.com/company/giving-is-human",
    portalURL: prod ? "https://portal.givingishuman.com" : "https://portal.beta.givingishuman.co.uk",
    donateURL: prod ? "https://donate.givingishuman.co.uk" : "https://donate.beta.givingishuman.co.uk",
    websiteURL: prod ? "https://www.givingishuman.com" : "https://gih-site-staging.web.app",
    websiteLinkText: "www.givingishuman.com",
};

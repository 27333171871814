import {company} from "./constants.js";

export const cookiePolicy = {
    what: 'cookiePolicy',
    title: `${company.tradingName} COOKIE POLICY`,
    body: `1. INFORMATION ABOUT OUR USE OF COOKIES
1.1 The ${company.tradingName} websites (reached through the domains ${company.domains} and application (together, the Site) use cookies to distinguish you from other users of the Site. This helps us to provide you with a good experience when you browse the Site and also allows us to improve the Site. By continuing to browse the Site, you are agreeing to our use of cookies. Please be aware that restricting cookies may impact on your user experience and may prevent you from using parts of the Site.
1.2 A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer, if you consent. Cookies contain information that is transferred to your computer’s hard drive.
We may use the following cookies:
• Strictly necessary cookies. These cookies are required for the operation of the Site. They include, for example, cookies that enable you to log in to secure areas of the Site, and process payments. These may include third party service provider cookies, such as payment service provider cookies.
• Analytical/performance cookies. These cookies allow us to recognise, and count the number of, visitors and to see how visitors move around the Site when they are using it. This helps us to improve the way the Site works, for example by ensuring that users are finding what they are looking for easily.
• Functionality cookies. These cookies enable us to recognise you when you return to the Site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
• Targeting cookies. These cookies record your visit to the Site, the pages you have visited and the links you have followed. We will use this information to make the Site and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for related purposes.
1.3 The individual cookies we use and the purposes for which we use them are described below:
1.3.1  Strictly necessary cookies
Stripe – These cookies are set by Stripe, the service we use to process payments for donations, to allow prevention of fraud. Stripe is used to make credit card payments. Stripe uses a cookie to remember who you are and to enable us to process payments without storing credit card information on its own servers.
You can find out more about Stripe cookies here: https://stripe.com/gb/legal/cookies-policy
1.3.2 Analytical/performance cookies
Google Analytics - this cookie helps us to:
(a) Estimate our audience size and usage pattern.
(b) Understand how visitors navigate to and through the Site.
(c) Track the effectiveness of digital marketing campaigns.
(d) Recognise you when you return to the Site.
You can find out more about Google Analytics here [https://marketingplatform.google.com /about/analytics/].
2. MANAGING COOKIES
The most popular web browsers all allow you to manage cookies. You can choose to accept or reject all cookies, or just specific types of cookies.
2.1 How to manage cookies in different web browsers
Most browsers will allow you to turn off cookies, although please note that turning off cookies may restrict your use of the Site. The following links provide information on how to modify the cookies settings on some popular browsers:
• Google Chrome [https://support.google.com /accounts/answer/61416]
• Microsoft Edge [https://support.microsoft.com /en-us/search?query=enable%20cookies%20in%20edge]
• Mozilla Firefox [https://support.mozilla.org /en-US/kb/websites-say-cookies-are-blocked-unblock-them]
• Opera [https://help.opera.com /en/latest/web-preferences]
• Apple Safari [https://support.apple.com /en-gb/guide/safari/sfri11471/mac]
Please note that we do not currently respond to Do Not Track (DNT) signals.
We strongly recommend that you leave cookies active, because they enable you to take advantage of the most attractive features of the Site, but this remains your personal choice. You can also visit the About Cookies website for more information about cookies and how to manage them.
3. THIRD PARTY COOKIES
Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control.
You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Site.
If you would like more information about these third-party cookies, it is available from www.youronlinechoices.com/uk.
Note that when third parties are collecting information about you, those third parties are acting as controllers in their own right (i.e. they are not acting on our behalf).
4. CHANGES TO THIS COOKIE POLICY
We will review, and where necessary update, this policy. If we have your e-mail address, we may notify you of any updates and, if necessary, ask you to confirm that you consent to such updates.
`
};

import {company} from "./constants";

export const npoTsAndCs = {
    what: 'npoTsAndCs',
    title: `${company.tradingName} TERMS & CONDITIONS FOR NON-PROFIT ORGANISATIONS`,
    body: `Please read these terms and conditions carefully before accessing our Site or App, creating an account, or engaging with our Communication Channels.
1.	Introduction
1.1	These are the terms and conditions (T&Cs) upon which you use or access our App, our Site, our Communication Channels, and your NPO Account
1.2	Our Site, App and Communication Channels (which, together with our user accounts, constitute our Services) are owned and operated by ${company.name} (trading as ${company.tradingName}), a company registered in England and Wales with company number ${company.registrationNumber} and whose registered office is situated at ${company.registeredAddress} (${company.tradingName}, our, we, us). To contact us, please email ${company.generalEmail}
1.3	${company.tradingName} operates the Site and App as an electronic intermediary platform that matches charities seeking volunteers for specific initiatives with individuals seeking volunteering opportunities and enables individuals to donate to specific NPOs (as defined below) Where an Individual (as defined below) and an NPO enter into arrangements, any terms, conditions or policies governing such arrangements are between the individual and the NPO, not ${company.tradingName}. ${company.tradingName} is simply the intermediary platform that facilitates introductions between NPOs and Individuals. 
1.4	The Services are provided on an as is and as available basis. We reserve the right to modify or discontinue, temporarily or permanently, any part of our Services from time to time. Where this is the case, we will let you know as soon as reasonably practicable and shall refund you for any sums paid in advance in respect of Services which will no longer be provided where we have not provided a materially suitable replacement. 
1.5	These T&Cs refer to our Privacy Policy and Cookies Policy, which also apply to your use of our Services.
1.6	By accessing our Services, you confirm that you accept these T&Cs and that you agree to comply with them. We may amend our T&Cs from time to time, so every time you wish to use our Services, please check the current T&Cs to ensure you understand the T&Cs that apply at that time. If you do not agree to these T&Cs or any future variation of these T&Cs, you should immediately stop using the Services. These T&Cs were most recently updated on 5 September 2023
1.7	From time to time, we may update or amend our Services, for example to reflect changes in our customer base and industry trends. This includes adding, removing and amending software tools, content and features in our sole discretion. This means the tools, content and features available on the Site or App may vary slightly from those marketed via our Site or App landing pages and Communication Channels. Any new features that augment or enhance the current Services, including the release of new tools and resources, shall be subject to these T&Cs and the continued use of the Services after any such changes shall constitute your consent to such changes.
2.	DEFINITIONS
2.1	The following definitions apply throughout these T&Cs:
App means the ${company.name} application, available from the App Store, Google Play and other such app stores from time to time;
Business Hours means 9:00  17.30 Monday to Friday on days that are not public holidays in England;
Communication Channels means our social media channels newsletter communications and any other channels through which we promote our Services;
Donor means a person who donates to an NPO via the Site or App and Donation and Donate shall be construed accordingly;
Volunteer means a person who signs up to our Site or App as a volunteer;
Individual means a Donor or a Volunteer;
Intellectual Property Rights means any patents, utility models, rights to inventions, copyright and neighbouring and related rights, moral rights, trademarks and service marks, business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to use and protect the confidentiality of confidential information (including know-how and trade secrets) and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world;
Initiative Descriptions means content published on the Site or App by NPOs (including but not limited to content published on pages created by NPOs using the Site or App) to explain, describe and/or promote Initiatives to users (including prospective Individuals), as well as any applicable guidelines or requirements imposed by such NPOs (including in relation to payment, reimbursement of expenses, time commitment, location and so on).
Initiatives means events and other initiatives about which NPOs have published information on the Site or App and in respect of which NPOs are seeking to recruit Volunteers or attract Donors;
NPO means a charity, social enterprise, or other non-profit organisation that signs up to the Site or App as such and (if applicable) promotes volunteering opportunities and related Initiatives and invites Donations;
NPO Account has the meaning given to it in clause 4.1;
NPO Data means personal data inputted or submitted to the Site or App by an NPO or on an NPOs behalf, including at the point of sign up and when using an NPO Account (e.g. when using live chat or messenger functionalities to interact with us or a Volunteer);
Services means our Site, our App, our Communication Channels and your NPO Account;
Site means any content hosted on the domains ${company.domains};
User Content means information and materials uploaded by users of the Site or App (for example, Volunteers and NPOs), including images, descriptions, Initiative Descriptions, marketing communications, reviews, videos, and other digital content; 
Users, you or your means our Site or App users, visitors to our social media channels, recipients of our communications, and our NPOs (as applicable); and
Individual Data means personal data inputted or submitted to the Site or App by an Individual, including at the point of sign up and when using their Account (e.g. when using live chat or messenger functionalities to interact with us or an NPO);
3.	ACCEPTABLE USE
3.1	Content on the Site and App
(a)	We make the Site and App available on an as is basis and, to the fullest extent permitted under applicable law, we disclaim any implied terms as to title, merchantability, fitness for a particular purpose and non-infringement
(b)	We may update and change our Site or App from time to time to reflect changes to our services, our users' needs and our business priorities. 
(c)	The content on our Site and App is provided for general information only. It is not intended to amount to advice on which you should rely. 
(d)	Although we make reasonable efforts to update the information on our Site and App, we make no representations, warranties or guarantees, whether express or implied, that the content on our Site or App is accurate, complete or up to date.
(e)	The Site or App may include User Content. User Content may not have been verified or approved by us and you accept that the views expressed and information provided by other users on our Site or App do not represent our views or values.
(f)	If you wish to complain about content on our Site or App, please contact us via ${company.supportEmail}
3.2	Accessing the Site or App
(a)	You are responsible for configuring your information technology, computer programmes, mobile phones, tablets and platforms to access our Site or App. 
(b)	We do not guarantee that our Site or App, or any content on either of them, will always be available or be uninterrupted. You accept that we may suspend, withdraw or restrict the availability of all or any part of our Site or App for business and operational reasons, as may the third party software providers on which we rely from time to time to keep our Site and App operational
(c)	If you choose to register for an account on the Site or App, you will have to provide certain information about yourself as prompted during the account registration process. If you do create an account, all the registration information you submit should be truthful and accurate. If for any reason any information you submit is or becomes untruthful, inaccurate and/or incomplete, you should promptly update that information to maintain its accuracy
(d)	If you choose, or you are provided with, a user identification code, password or any other piece of information as part of an account creation process (e.g. when creating a NPO Account) or our security procedures, you must treat such information as confidential and not disclose it to any third party
(e)	We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these T&Cs
(f)	If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at ${company.supportEmail}
(g)	You shall use all reasonable endeavours to prevent any unauthorised access to, or use of, our Site or App. If you suspect or become aware of any such unauthorised use of our Site or App (including your account, if applicable) or any other breach of its security, you shall immediately notify us of any details reasonably required in connection with such unauthorised access.
(h)	You can delete your account at any time, for any reason. To do so, please contact us via ${company.securityEmail}
(i)	You are responsible for ensuring that all persons who access our Site or App through your internet connection are aware of these T&Cs and other applicable terms, and that they comply with them. 
3.3	Uploading or posting content to our Site and App
(a)	Any User Content uploaded or posted to our Site or App must be done in accordance with these T&Cs.
(b)	Any facts comprised in User Content must be accurate and any opinions must be genuinely held. 
(c)	User Content must comply with all applicable laws in England and Wales and in any other country from which such User Content can be viewed and not be in contempt of court.
(d)	User Content must not: 
(i)	be defamatory of any person; be obscene, offensive, hateful or inflammatory; be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety; promote violence or any illegal content or activity; or bully, insult, intimidate humiliate harass, upset, embarrass, alarm or annoy any other person; 
(ii)	include pornographic, indecent, obscene, or child sexual abuse material
(iii)	promote discrimination of any kind, including discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; 
(iv)	infringe any Intellectual Property Rights; 
(v)	be likely to deceive any person, or give the impression that your contribution emanates from us if this is not the case;
(vi)	breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence; 
(vii)	impersonate any person or organisation or misrepresent your identity or affiliation with any person or organisation; 
(viii)	advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse, or contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, made are likely to understand such statement as constituting a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism; or
(ix)	contain any advertising or promote any products, services, websites or resources provided by third parties. 
(e)	You agree to not use the Site or App to generate unsolicited advertisements or spam, or to use any automatic or manual process to search or harvest information from the Site or App
(f)	If you post User Content, you warrant that any User Content complies with the standards set out in these T&Cs and you will be liable to us and indemnify us for any breach of this warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
(g)	If you access User Content, you accept that we are not responsible for such User Content and therefore make no representations, warranties or guarantees, whether express or implied, that the User Content is accurate, complete or up to date.
(h)	We have the right to immediately remove (temporarily or permanently) any posting you make on (or messages you send through) the Site or App if, in our opinion, your posts or messages do not comply with the standards set out in these T&Cs.
(i)	You accept that we reserve the right to disclose your identity to any third party who is claiming that any User Content posted or uploaded by you to our Site App or Communication Channels constitutes a violation of their Intellectual Property Rights, or of their right to privacy.
(j)	You agree not to copy, distribute or make available any User Content uploaded or made available by us or other users of the Site or App
(k)	You are solely responsible for securing and backing up your User Content.
3.4	Viruses
(a)	We do not guarantee that our Site or App will be secure or free from bugs or viruses, so you should use your own suitable virus protection software. Accordingly, you agree not to hold us accountable for such bugs or viruses.
(b)	You must not misuse our Site or App by knowingly introducing or permitting the introduction of viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our Site or App, the servers on which our Site or App are stored or any server, computer or database connected to our Site or App. You must not attack our Site or App via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Services will cease immediately.
(c)	You must not use bots or other automated methods to register accounts on the Site or App
4.	Site and app usage
4.1	In consideration of you signing up to the Site or the App as an NPO (creating a NPO Account), and subject to these T&Cs and any other conditions of registration highlighted during the registration process we will grant you a non-transferable, non-exclusive, non-sublicensable worldwide licence to use the Site or App for as long as your NPO Account remains live (i.e. has not been deleted)
4.2	NPO Accounts will continue in perpetuity unless and until terminated in accordance with these T&Cs.
4.3	You are solely responsible for properly cancelling or terminating your NPO Account, and can do so at any time To cancel your NPO Account, please contact us via ${company.securityEmail}
4.4	All of your data will be inaccessible via the Site and App immediately upon cancellation or termination of your NPO Account. Once permanently deleted, you accept that the information cannot be recovered. You are therefore advised to export or otherwise ensure you have copies of back-ups of your data prior to this date.
4.5	You agree not to attempt to create a NPO Account if you are under the age of eighteen (18), or to permit or facilitate access to our Services for anybody under the age of eighteen (18). If you are unable to provide valid identification on request (including appropriate authorisation for any NPO on whose behalf you are setting up a NPO Account), the relevant NPO Account(s) will be terminated immediately. 
4.6	We may terminate your NPO Account immediately and without liability at any time and for any reason.
5.	NPO Initiatives 
5.1	From time to time, we may update or amend the range of Initiatives that we advertise, offer, facilitate volunteering placements for or enable Donations towards. This includes adding, removing and amending Initiatives in our sole discretion. This means the Initiatives available on our Site or App may vary slightly from those marketed via our Communication Channels. 
5.2	NPOs are responsible for publishing Initiative Descriptions and ensuring that Initiative Descriptions are accurate, complete and up to date, and accordingly you agree to indemnify us for any liability that we may incur as a result of changes, discrepancies or inaccuracies in respect of any such Initiative Descriptions that you provide. An Initiative can be designed to attract Volunteers or Donations (as set out in section 6 below).
5.3	In addition to our Terms and Conditions for Individuals who have created accounts on our App, NPOs are encouraged to impose their own terms and conditions for each Initiative promoted through the Site or App. You agree that any issues or disputes arising in respect of an Initiative, including (but not limited to) issues or disputes arising in relation to the actions and behaviours of Volunteers or prospective Volunteers, are between you and such Volunteers and we are not a party to such issues or disputes. Accordingly, we take no responsibility and accept no liability in respect of such issues or disputes. If you experience any issues  or disputes in relation to Initiatives, you are advised to resolve these directly with the Volunteers or prospective Volunteers involved.
5.4	We do not provide any checking or vetting of Volunteers to verify their experience or suitability for any Initiative. Responsibility for any Volunteer checking or vetting shall, at all times, remain with the NPO. 
5.5	You agree not to attempt to publish, promote or create pages for Initiatives that accept Volunteers who are under sixteen (16) years of age, nor to engage with or recruit prospective Volunteers who are under sixteen (16) years of age. Where other age restrictions apply under applicable law (or under your own terms and conditions) in respect of a particular Initiative, you agree not to recruit or engage with Volunteers who fail to meet such minimum age requirements.
5.6	You agree to adhere at all times to any legal requirements and codes of conduct that exist to protect volunteers and contractors, including (but not limited to) requirements codes and recommendations relating to supervision, insurance, background checks (e.g. Disclosure and Barring Service checks), illegal substances, and age restrictions.
6. Donations
6.1	The Site and App provide the facility for individuals to Donate to an NPO on either a one-off or regular basis and thereby become Donors for the purposes of these terms and conditions. Qualifying Donors may also add Gift Aid to Donations. This service is provided to enable individuals with UK-registered credit cards or bank accounts to donate to UK-registered NPOs. 
6.2	There is no obligation upon any Volunteer to become a Donor or vice-versa, or upon any NPO to become a Donee.
6.3	Donors may, at their sole discretion, indicate their intention to add Gift Aid to a Donation. In order to be eligible to receive GiftAid, NPOs must be registered with HMRC for that purpose and must provide proof of that before we will allow eligible Donors to GiftAid their donations. NPOs wishing to receive GiftAid must provide their HMRC Charity Reference Number to us. In order for a Donation to qualify for give Gift Aid, Donors must confirm they are eligible for Gift Aid under the then-current Gift Aid eligibility criteria (which may change from time to time) and give the NPO permission to claim the tax relief on the Donation. By agreeing to take part in Gift Aid, Donors also consent to us, the NPO and HMRC processing their personal data in the form of their address and contact information. We require this personal data in order for us to securely transfer it to HMRC, who will use it to pay the NPO directly the amount of Gift Aid that is due.
6.4 You warrant that you shall use all money received through the Donations for the purpose described in the corresponding Initiative. Where the funds raised pursuant to an Initiative are for the purposes of meeting a fixed cost, the Initiative must state clearly (ii) how any monies raised in excess of a target will be used; and (ii) how such funds will be used if the Initiative fails to reach its stated target and as a result it is not possible to use the money for the original purpose.
6.5	The processing of all Donations shall be conducted securely by our third party payment provider, Stripe. NPOs may be required to enter into their own contractual arrangements with Stripe before becoming able to receive Donations. NPOs should note that we shall deduct our processing fee of 1.9% + 20p from the Donation (the Processing Fee). The Processing Fee will include Stripes fee which is set in accordance with their own terms and conditions. Additionally, we will deduct a charge of £2 from the first donation received by each NPO in a calendar month to cover the active account fee levied by Stripe. Our fees shall be shown to the Donor at the point of Donation. Stripe is authorised and regulated by the United Kingdom Financial Conduct Authority in relation to its payment processing activities.
6.6	You warrant that (i) you are authorised to use any bank account which you nominate to Stripe; and (ii) that all information you provide to Stripe is true and accurate. Any personal data which you send to Stripe shall be subject to Stripes privacy policy to the exclusion of ours.
6.7	All NPO queries, concerns, comments and disputes relating to the processing of Donation payments must be raised in accordance with Stripes terms and conditions. NPOs are strongly advised to familiarise themselves with Stripes terms and conditions and privacy policy. In addition, NPOs attention is specifically drawn to the provisions of section 12 (Third Party Providers) below.
6.8	After a Donation has been made, NPOs further dealings in relation to that Donation shall be solely between the NPO and Stripe. ${company.tradingName} shall have no liability whatsoever to NPOs for any fraudulent activity on the part of any Donor. If a donation proves in some way to be fraudulent or disputed then Stripe will refund the payment at the behest of the Donors bank or card provider. Stripe will debit the amount from the NPO account and if they fail to do that, they will debit it from our account.
6.9	${company.tradingName} does not provide, or purport to provide, any accountancy, taxation or financial advice in relation to the implications of (i) deciding to become a Donee; or (ii) any Donation. NPOs should take their own specialist advice in this regard and ${company.tradingName} disclaims any and all liability arising from any decision made by an NPO notwithstanding the provisions of this clause.
7.	Thirdparty website & resources
7.1	Thirdparty software providers
(a)	You agree not to hold us responsible if defective thirdparty software (including software sold or made available by a seller) damages a device or digital content belonging to you and accept that we will never accept responsibility for damage which you could have avoided by following our advice to apply an update offered to you free of charge or for damage which was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us or the relevant software providers.
7.2	Thirdparty resources
(a)	Where our Services contain links to third parties, including (but not limited to) links to NPO profiles, third party website content, social media channels, platforms, individuals, organisations, Initiatives, services, or other resources (including content, communications, mailing lists, advice and information) (Third-Party Resources), these links are provided for your information only
(b)	Links to Third-Party Resources should not generally be interpreted as recommendations that you interact with such Third-Party Resources, or as approval by us of those Third-Party Resources or information you may obtain from them and you accept that we have no control over the nature or availability of such Third-Party Resources. 
(c)	If you access, purchase or use any Third-Party Resources:
(i)	You do so solely at your own risk and we make no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such Third-Party Resources, or any transactions completed and any contracts entered into by you with any such Third-Party Resources; and
(ii)	By using such Third-Party Resources, you agree to also be bound by any terms and conditions applicable to the use of such Third-Party Resources and accept that you may need to pay additional fees to access those Third-Party Resources. Accordingly, we recommend that you refer to any applicable terms and conditions and policies prior to using any Third-Party Resources.
7.3	Linking to our Services
(a)	You may link to our Services, provided you do so in a way that is fair and legal, does not damage our reputation or take advantage of it, and does not suggest any form of association, approval or endorsement on our part where none exists.
(b)	You must not establish a link to our Services in any websites, apps or resources that are not owned by you, unless you have been given express permission to do so by the owner of such websites apps or resources.
(c)	Our Services must not be framed on any other website or app or within any resources
(d)	We reserve the right to withdraw linking permission at any time and in our sole discretion
(e)	If you wish to link to or make any use of our Services other than as set out in these T&Cs, please contact ${company.supportEmail}
8.	LIMITATIONS ON USE OF THE SERVICES
8.1	You must not:
(a)	print off, print screen, download, copy, modify, record, duplicate, reproduce, create derivative materials from, modify, frame, mirror, republish, transmit, distribute, display, share, distribute, transmit, retransmit, transfer, disseminate, broadcast, circulate or otherwise use the Services or any portion of the Services, including any portion of the text, images, audio, videos, pages, structures, HTML, CSS, JavaScript or visual design elements that comprise our Services in any form or by any means (Sharing Content) without our prior written consent except as permitted by these T&Cs;
(b)	use the Services in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these T&Cs; or 
(c)	act fraudulently or maliciously, for example, by decompiling the Site or App or inserting malicious code, including viruses, or harmful data, into the Site or App
8.2	You agree that we shall have the right to: 
(a)	refuse or withdraw your access to the Site or App in accordance with applicable laws (with or without notice) if we judge that you have violated or breached any of these T&Cs;
(b)	amend, update or remove User Content, Initiatives, and any other aspects of our Services, without notice and in our sole discretion; 
(c)	amend or update Initiative Descriptions;
(d)	terminate your access to the Site or App (including any account you may have registered with us) without notice at any time following your unauthorised use of the Site or App, or your breach of these T&Cs; and
(e)	report you to the police or other judicial body if we believe in our sole and absolute discretion that your conduct (whether in using the Site or App, creating a NPO Account, or promoting or running Initiatives) is or may be unlawful.
8.3	Where we consent to you Sharing Content, you agree that our status (and that of any identified contributors) as the authors of the relevant content must always be acknowledged, and that we can withdraw our consent at any time (at which point, you will return, remove and/or destroy the content and any copies thereof, at our request and in our sole discretion, as soon as practicable). 
8.4	You shall not use any part of the Services in order to build a product, service, offering or following, whether for commercial purposes or otherwise, on your own behalf or for a third party.
8.5	You shall not license, sell, resell, rent, commercially exploit, make available to third parties, or enable any third parties to access our Site or App, for example by sharing your log-in details with third parties. To clarify, your log-in details may only be used by you; any other individual or business must only access Services behind a member paywall using their own distinct log-in details.
9.	Intellectual property 
9.1	Any User Content you upload or post to our Site or App will be considered non-confidential. Unless otherwise agreed, you retain all of your ownership rights in your content, but in any event you are required to grant us a perpetual, worldwide, non-exclusive, royalty-free, transferable licence to use, store and copy that content and to distribute and make it available to third parties for any purpose whatsoever (for example, to use within our marketing materials, press announcements, social media posts, Site or App pages, and so on).
9.2	 We are the owner or the licensee of all Intellectual Property Rights in our Services, including the material published on our Site or App and any software, logos, branding or domains contained within or made available through the Site or App. Those works are protected by copyright laws and treaties around the world and all such rights are reserved. You are not permitted to use any Intellectual Property Rights without the prior written consent of the owner.
9.3	These T&Cs do not grant you any rights to, under or in, any patents, copyright, database rights, trade secrets, trade names, trade marks (whether registered or unregistered), or any other rights or licences in respect of the Services.  
9.4	You are not permitted to use our business name, trading name, logos or branding without our approval and you shall not remove or in any manner alter any logo, brand name, product identification, proprietary mark, trade mark notice, copyright notice, or other notices contained in or comprising part of the Services.
10.	Liability
10.1	If you have any questions or complaints about our Services, please contact us via ${company.supportEmail} 
10.2	We do not warrant that:
(a)	the Services will meet your specific requirements;
(b)	the Services will be uninterrupted, timely, secure, or error-free;
(c)	the quality of any Initiatives, services, information, or other material purchased or obtained by you through the Services will meet your expectations; 
(d)	the Site or App will be free of viruses or anything else which may be harmful or destructive; and/or
(e)	any errors in the Service will be corrected.
10.3	You accept that we shall not be liable to you or any third party in connection with any modification, price change, suspension or discontinuance of the Services.
10.4	You agree to indemnify any other party to these T&Cs against all claims, liabilities, damages, costs, and expenses suffered or incurred by that other party as a result of your breach of these T&Cs.
10.5	All Initiatives are featured without a warranty of any kind (express or implied) from us You agree that, to the fullest extent permitted by law, we shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any direct, indirect or consequential loss however so arising under these T&Cs or any terms and conditions you enter into with a Volunteer Accordingly, you agree not to hold us responsible for any loss or other damages which you may suffer in connection with your engagement with Initiatives or reliance on communications with and User Content published by Volunteers, and accept that your only recourse in respect of any Initiative-related grievances is to raise a claim directly with the relevant Volunteer
10.6	We shall have no liability to you under these T&Cs if we are prevented from or delayed in performing our obligations, or from carrying on our business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes (whether involving our workforce or any other party), failure of a utility service or transport or telecommunications network, acts of God, wars, riots, civil commotions, malicious damage, epidemics, pandemics, compliance with any law or governmental orders, rules, regulations or directions, accidents, breakdowns of plant or machinery, fires, floods, storms or default of suppliers or subcontractors
10.7	Whether you are a consumer or a business user, we do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
10.8	(a)	We exclude all implied conditions, warranties, representations or other terms that may apply to the purchase, access or use of our Services
(b)	We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with your use of, or inability to use, our Services or your use of or reliance on any content forming part or all of our Services
(c)	In particular, we will not be liable for: loss of profits, sales, donations, business, or revenue; business interruption; loss of anticipated savings; loss of opportunity, goodwill or reputation; or any indirect or consequential loss or damage.	
11.	Data Protection
11.1	We will only use your personal information as set out in our Privacy Policy
11.2	You agree that by publishing information about an Initiative, you are consenting to us transferring the contact information and other personal data provided in relation to one or more of your representatives to Individuals so that they can communicate with you. 
11.3	Where we facilitate your access to personal data (including but not limited to Individuals personal data), you agree only to use such personal data for the purpose of communicating with Individuals about the specific Initiative(s) in respect of which such Individuals have directly expressed an interest through the Site or App. 
11.4	You shall be responsible for the NPO Data and complying with any laws and regulations applicable to the NPO Data and the NPOs use of the Services the Site and the App You warrant that your collection and use of any personal information or data provided while using the Services the Site and the App complies with all applicable data protection laws, rules, and regulations, as well as your own privacy policy in place from time to time
12.	THIRD PARTY PROVIDERS
12.1	You acknowledge that the Services may enable or assist you to access the website content of, correspond with, and engage with services supplied by, third parties via third-party branded pages on the Site or App, as well as those third-parties own websites and that you do so solely at your own risk. We make no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party page or website, or any transactions completed or contracts entered into by you with any such third party. Such arrangements are between you and the relevant third party, and not us. We recommend that you refer to the third party's own terms and conditions and privacy policy prior to engaging with the relevant third-party.
13.	ENTIRE AGREEMENT 
13.1	Subject to clause 4.1, this agreement constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.
13.2	Each party acknowledges that in entering into this agreement it does not rely on, and shall have no remedies in respect of, any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in this agreement.
13.3	Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this agreement. Nothing in this clause shall limit or exclude any liability for fraud.
14.	Additional CLAUSES
14.1	Technical support in relation to the Services is provided via email. If you require support, please contact ${company.supportEmail} We will do our best to reply to you as soon as possible, but generally replies will only be sent during Business Hours.
14.2	We may sublicense, assign, transfer, charge or sub-contract our rights or obligations under these Terms and Conditions to a third party. You shall not, without our prior written consent, sublicense, assign, transfer, charge or sub-contract any element of the Services or any of your rights or obligations under these T&Cs to any third party, whether with or without consideration, without our prior written consent.
14.3	We may transfer our rights and obligations under these T&Cs to another organisation, for example if we decide to sell the company. 
14.4	Provisions of these T&Cs that expressly or by their inherent nature should survive termination or expiry of these T&Cs shall survive termination or expiry and shall continue to have effect and be binding.
14.5	These T&Cs do not confer any rights on any third party pursuant to the Contracts (Rights of Third Parties) Act 1999
14.6	If any provision or part-provision of these T&Cs is or becomes invalid, illegal or unenforceable, it shall be deemed to be amended to the minimal extent possible so that it becomes valid, legal and enforceable and, if this is not possible, it shall be deleted, but that shall not affect the validity and enforceability of the rest of these T&Cs.
14.7	No failure or delay by us to exercise any right or remedy provided under these T&Cs or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.
14.8	These T&Cs and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England and Wales. You irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these T&Cs or their subject matter or formation (including non-contractual disputes or claims).
`
};

import {company} from "./constants.js";

export const privacyPolicy = {
    what: 'privacyPolicy',
    title: `${company.tradingName} PRIVACY POLICY`,
    body: `1. INTRODUCTION
This privacy policy (Policy) relates to your personal information in connection with your use of and access to the ${company.tradingName} sites (reached through the domains ${company.domains}) and mobile phone applications (together, the GIH Platform) and any services provided by GIH (the GIH Platform and services together forming the GIH Services).
We are committed to protecting your information and your right to privacy. If you have any questions or concerns about our Policy, or our practices with regards to your personal information, please contact us via ${company.securityEmail}.
When you use the GIH Services, you trust us with your information and we take your privacy very seriously. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this Policy that you do not agree with, please discontinue your use of the GIH Services.
All capitalised terms used and not defined in this Policy are defined in our T&C’s.
2. CHANGES TO THIS POLICY OR YOUR PERSONAL INFORMATION
We review this Policy regularly and it is your responsibility to check regularly and determine whether you still agree to comply with the Policy. If you do not agree to any changes to this Policy then you must immediately stop using the GIH Services. In the event we make any significant changes to this Policy we will use our reasonable endeavours to inform you of such changes in advance in writing.
It is important that the personal information we hold about you is true, complete, accurate and current. Accordingly, you must notify us of any changes to your personal information.
3. ABOUT US
The GIH Services are owned and operated by ${company.name} (trading as “${company.tradingName}”), a company registered in England and Wales with company number ${company.registrationNumber} and whose registered office is situated at ${company.registeredAddress} (GIH/we/us/our). GIH is the controller (also known as a data controller) of, and is responsible for, your personal information. The term “you” refers to the user wishing to access and/or use the GIH Services.
The GIH Platform provides an intermediary platform that:
•	matches charities seeking volunteers for specific initiatives with individuals seeking volunteering opportunities; and
•	enable Individuals to make donations to our network of NPO’s.
4. INFORMATION WE MAY COLLECT ABOUT YOU
4.1 Personal information you disclose to us
In Short: We collect personal information that you provide to us, including information such as your name, contact information, month and year of birth, and geographical location.
(a) Where and how we collect personal information
We collect personal information that you voluntarily provide to us when registering to use and actually using the GIH Services, or otherwise contacting us.
If you give us information on behalf of another individual, in providing that information to us you are confirming that you have sought and obtained, from that individual, permission for you to:
• Consent on their behalf to the processing of their personal data;
• Receive on their behalf any data protection notices; and
• If relevant, consent to the transfer of their personal data abroad,
and you shall indemnify us and hold us harmless against any claims, actions, proceedings, losses, damages, costs (including reasonable legal costs) and expenses (including taxation), in each case of any nature whatsoever, arising out of or in connection with your failure to obtain these permissions.
More particularly, the personal information that we collect depends on the context of your interactions with us, the choices you make and the products and features you use. For example:
• We collect personal information via our website, mobile applications and other technical systems.
• We collect personal information when you sign up to, participate in or receive a service from us, for example where you contact us, request information online, make a donation, report an issue, provide feedback or enter a live chat.
• Our website also uses cookies and collects IP addresses (for more information on this, see our Cookie Policy).
(b) The types of personal information we may collect
When you use the GIH Services and/or when you otherwise deal with us, we may collect the following information about you (the Information):
• Identity Data, which includes your first name and last name.
• Tax payer status and whether your donations are eligible for Gift Aid, including your home address.
• Donation information which includes your donation amounts and frequency and any preferences and data regarding the NPO’s you donate to.
• Contact Data, which means the data we use to contact you including your location, email address and contact number.
• Profile Data, which includes your username and log-in data.
• Usage Data, which includes Information about how you use the GIH Services. This includes your browsing and application usage patterns and Information such as how long you might spend on one of our pages on the GIH Platform and what you look at and for, the page that referred you to the GIH Platform and the click stream during your visit to our website or application, page response times, and page interaction Information (for example, clicks you make on a page).
• Marketing and Communications Data, which includes your preferences with regards to receiving marketing from us and your other communication preferences.
• Other Information relevant to services, customer surveys, questionnaires and/or offers.
4.2 Sensitive personal information
In Short: We do not ask you to provide sensitive personal data.
We do not ask you to provide any sensitive personal data (this includes information about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, health and genetic and biometric data, and criminal convictions and offences).
However, in the course of providing the GIH Services to you or organisations with which you are connected, we may process sensitive personal information about you if such information is uploaded or submitted through the GIH Platform by you or a third party.
4.3 Personal information from third parties
In Short: We may receive information about you from third parties, and we may use this information to provide, improve and personalise the GIH Services.
Occasionally we may receive information about you from other sources, which we will add to the information we already hold about you in order to help us provide, improve and personalise the GIH Services.
4.4 Information automatically collected
In Short: Some Information – such as IP addresses and/or browser and device characteristics – is collected automatically when you use the GIH Platform.
We automatically collect certain Information when you visit, use or navigate the GIH Platform. This Information does not reveal your specific identity (unless your device name is the same as your name) but may include device and usage Information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, Information about how and when you use the GIH Platform and other technical Information. This Information is primarily needed to maintain the security and operation of the GIH Platform, and for our internal analytics and reporting purposes.
Like many businesses, we also collect Information through cookies and similar technologies. You can find out more about this in our Cookie Policy.
5. HOW DO WE USE YOUR INFORMATION?
In Short: We process your Information for purposes based on legitimate business interests, the fulfilment of our contract with you, compliance with our legal obligations, and/or your consent.
We use your Information collected via the GIH Services for a variety of business purposes described below. We process your Information for these purposes in reliance on our legitimate business interests (Business Purposes), in order to enter into or perform a contract with you (Contractual Reasons), with your consent (Consent), and/or for compliance with our legal obligations (Legal Reasons). We indicate the specific processing grounds we rely on next to each purpose listed below.
We may process your Information for the following purposes:
• To take steps towards entering into a contract with you, to provide the GIH Services (Contractual Reasons). This includes collecting and using your personal information to:
• Prepare an agreement with you;
Accept a donation from you on behalf of an NPO;
• Manage any accounts you hold with us;
• Contact you for reasons related to the GIH Services;
• Notify you of any changes to our Site or to the GIH Services that may affect you; and
• Resolve disputes.
• To pass it onto certain third parties (for example, charities using our platform) (Contractual Reasons).
• To contact you regarding enquiries made by you in relation to the GIH Services and/or to provide information you have requested (Business Purposes).
• To send administrative Information to you for Business Purposes, Legal Reasons and/or possibly Contractual Reasons. We may use your Information to send you product, service and new feature information and/or information about changes to our terms and conditions and policies, as may be in place from time to time.
• To send you marketing and promotional communications for Business Purposes and/or with your Consent. We and the charities you express an interest in may use your Information for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails (see below for further details).
• To administer promotions for our Business Purposes and/or with your Consent.
• To request feedback for our Business Purposes and/or with your Consent. For example, we may use your Information to request feedback and to contact you about your use of the GIH Platform.
• To protect the GIH Services for Business Purposes and/or Legal Reasons. We may use your Information as part of our efforts to keep the GIH Services safe and secure (for example, for the purposes of monitoring and/or preventing fraud).
• To enforce our terms and conditions and policies for Business Purposes, Legal Reasons and/or possibly Contractual Reasons.
• To respond to legal requests and prevent harm for Legal Reasons. For example, if we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
• We may use your Information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve the GIH Services, our products and services, our marketing and your experience.
6. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
In Short: We only share Information for Contractual Reasons, to fulfil our Business Purposes, or with your Consent.
We only share and disclose your Information in the following situations:
• Contractual. We may share your Information with payment service providers, charities, social enterprises and other partners via the GIH Platform where you have indicated that you want to share your Information with such third parties (for example, if you make a donation to an NPO or you submit your interest in volunteering for a specific initiative through the GIH Platform). 
• Compliance with Legal Obligations. We may disclose your Information where we are legally required to do so in order to comply with applicable laws, governmental requests, judicial proceedings, court orders, or legal processes, such as in response to a court order or a subpoena (including in response to requests from public authorities in order to meet national security or law enforcement requirements).
• Vital Interests. We may disclose your Information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, illegal activities or situations involving potential threats to the safety of any person, or where we believe it is necessary for the purpose of providing evidence in connection with litigation proceedings in which we are involved.
• Third Party Service Providers. We may share your Information with third party vendors, service providers, contractors or agents who perform services and require access to such Information to carry out that work. Such third parties will only have access to your Information to the extent that they need to perform those services. They are required to keep your Information confidential and may not use it other than as we ask them to and always in accordance with this Policy.
• Business Transfers. We may share or transfer your Information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
• With your Consent. We may disclose your Information for any other purpose with your Consent, including with any person who you have named as a person we can contact to discuss your account and any agent or representative of yours].
We may disclose aggregated, anonymous Information (i.e. Information from which you cannot be personally identified), or insights based on such anonymous Information, to selected third parties, including (without limitation) analytics and search engine providers to assist us in the improvement and optimisation of the GIH Services. In such circumstances we will not disclose any Information which can identify you personally.
7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
In Short: We may transfer, store, and process your Information in countries other than the UK, but will take all reasonable steps to ensure it is protected.
Whenever we transfer your Information outside of the UK, we will take all reasonably practicable measures to protect your Information in accordance with this Policy and applicable laws. To the extent that any transfer requires approved safeguards to be in place, we will only transfer your Information to countries that have been deemed to provide an adequate level of protection for personal data. Where we use certain service providers, we may use specific contracts approved for use in the UK which give personal data the same protection it has in the UK. Please contact us if you want further information on the specific mechanism(s) used by us when transferring your personal data out of the UK.
8. THIRD PARTY WEBSITES
In Short: We are not responsible for the safety of any Information that you share with third party providers who feature or advertise, but are not affiliated with, the GIH Services.
The GIH Platform may feature links to third party websites or social media channels, or contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications.
We cannot guarantee the safety and privacy of data you provide to any third parties, including organisations featured on the GIH Platform. Any data collected by third parties – including Information you provide to third parties via the GIH Platform, for example via our live chat feature – is not covered by this Policy.
We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from the GIH Platform. You should review the policies of such third parties and contact them directly if you have any related questions.
9. FOR HOW LONG DO WE KEEP YOUR INFORMATION?
In Short: We keep your Information for as long as necessary to fulfil the purposes outlined in this Policy, unless a longer retention period is permitted or required by law.
We will only keep your Information for as long as it is necessary for the purposes set out in this Policy, unless a longer retention period is required or permitted by law (such as tax law, accounting requirements or other legal or regulatory requirements).
When we have no ongoing Business Purpose to justify the processing of your Information, we will either delete or anonymise it, or, if this is not possible (for example, because your Information has been stored in backup archives), then we will securely store your Information and isolate it from any further processing until deletion is possible.
10. HOW DO WE KEEP YOUR INFORMATION SAFE?
In Short: We aim to protect your Information through a system of organisational and technical security measures.
We have implemented appropriate technical and organisational security measures designed to protect the security of any Information we process.
We have procedures in place to deal with any suspected data security breach. We will notify you and any applicable supervisory body of a suspected data breach where we are legally required to do so.
However, please also remember that we cannot guarantee that the Internet itself is 100% secure. Although we will use our reasonable endeavours to protect your Information, we cannot guarantee the security or integrity of personal Information that is transferred from you or to you via the Internet. Transmission of personal Information to and from the GIH Platform is therefore at your own risk. You should only access our services within a secure environment.
11. DO WE COLLECT INFORMATION FROM MINORS?
In Short: We do not knowingly collect data from or market to children under 16 years of age.
We do not knowingly solicit data from or market to children under 16 years of age. By using the GIH Services, you represent that you are at least 16 years of age. If we learn that Information from users less than 16 years of age has been collected, we will deactivate the relevant account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under 16 years of age, please contact us immediately via ${company.securityEmail}.
12. YOUR CONSENT TO PROCESSING
You will be required to give Consent to certain processing activities before we can process your Information. Where applicable, we will seek Consent from you when you first submit Information to or through the GIH Services.
If you have previously given your Consent you may freely withdraw such Consent at any time. You can do this by emailing ${company.securityEmail} or, where applicable, clicking the ‘Unsubscribe’ button that appears in mailing list communications.
If you withdraw your Consent, and if we do not have another legal basis for processing your Information, then we will stop processing your Information. If we do have another legal basis for processing your Information, then we may continue to do so subject to your legal rights.
Please note that if we need to process your Information in order for you to use the GIH Services and you object or do not provide Consent to us processing your Information, you accept that the GIH Services will no longer be available to you.
13. MARKETING AND OPTING OUT
If you have given Consent to marketing we may contact you about our products, services, promotions and special offers. If you no longer wish to receive such Information, you can withdraw your Consent at any time by sending an email to ${company.unsubscribeEmail} or unsubscribing from the communications.
Where you opt out of receiving marketing messages, this will not apply to personal data provided to us in connection with your use of the GIH Services.
14. ACCOUNT INFORMATION
You may at any time review or change the Information in your account by logging into your GIH account.
If you wish to terminate your GIH account, press the Delete Account button in the app or contact us via ${company.securityEmail} and we can arrange this for you. Some Information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our terms and conditions, and/or comply with legal requirements.
15. WHAT ARE YOUR PRIVACY RIGHTS?
In Short: You have certain rights in respect of your Information.
You have certain rights in relation to the Information that we hold about you. Details of these rights and how to exercise them are set out below. Please note that we will require evidence of your identity before we are able to respond to any requests. This is a security measure to ensure that your Information is not disclosed to a person who does not have the right to receive it. We may also contact you to ask you for further Information in relation to your request to speed up our response. To exercise or discuss any of your rights, please contact us via ${company.securityEmail}
• Right of Access. You have the right at any time to ask us for a copy of the Information that we hold about you and to check that we are lawfully processing it. Where we have good reason, and where data protection law permits, we can refuse your request for a copy of your Information, or certain elements of the request. If we refuse your request or any element of it, we will provide you with our reason(s) for doing so.
• Right of Correction or Completion. If Information we hold about you is not accurate or is out of date and requires amendment or correction, you have a right to have the data rectified or completed.
• Right of Erasure. In certain circumstances, you have the right to request that the Information we hold about you is erased (for example, if the Information is no longer necessary for the purposes for which it was collected or processed or our processing of the Information is based only on your Consent and there are no other legal grounds on which we may process the Information).
• Right to Object to or Restrict Processing. In certain circumstances, you have the right to object to our processing of your Information (for example, if we are processing your Information on the basis of our legitimate interests but there are no longer any compelling legitimate grounds to justify our processing overriding your rights and interests).
You may also have the right to restrict our use of your Information, for example during a period in which we are verifying the accuracy of your Information in circumstances where you have challenged the accuracy of that Information.
• Right of Data Portability. In certain instances, you have a right to receive the Information that we hold about you (or a portion thereof) in a structured, commonly used and machine-readable format.
In such circumstances, you can ask us to transmit your Information to you or directly to a third-party organisation on your behalf.
While we are happy for such requests to be made, we are not able to guarantee technical compatibility with a third-party organisation’s systems. We are also unable to comply with requests that relate to personal Information of others without their consent.
If we are relying on Consent to process your Information, you have the right to withdraw your Consent at any time. Please note however that this will not affect the lawfulness of the processing that occurred before the withdrawal of such Consent.
16. CONTACT US
We welcome your feedback and questions on this Policy. If you wish to contact us about this Policy or have any other questions, please email us via ${company.securityEmail}
You have the right to make a complaint at any time to the Information Commissioner’s Office (the ICO), the UK supervisory authority for data protection issues (https://ico.org.uk/concerns). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.
`
};

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
class InactivityTimer {
  constructor(_ref) {
    let {
      gracePeriod,
      onExpiry
    } = _ref;
    this.gracePeriod = gracePeriod;
    this.onExpiry = onExpiry;
    this.eventHandler = this.updateExpiry.bind(this);
    this.heartbeat = null;
    this.start();
  }
  start() {
    console.log('Starting inactivity timer');
    this.updateExpiry();
    this.heartbeat = setInterval(() => {
      if (this.expiry < Date.now()) {
        var _this$onExpiry;
        console.log('Inactivity timer expired');
        (_this$onExpiry = this.onExpiry) === null || _this$onExpiry === void 0 || _this$onExpiry.call(this);
        this.stop();
      }
    }, 10000);
    window.addEventListener("mousedown", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }
  stop() {
    if (this.heartbeat) {
      console.log('Stopping inactivity timer');
      clearInterval(this.heartbeat);
      this.heartbeat = null;
      window.removeEventListener("mousedown", this.eventHandler);
      window.removeEventListener("scroll", this.eventHandler);
      window.removeEventListener("keydown", this.eventHandler);
    }
  }
  updateExpiry() {
    this.expiry = Date.now() + this.gracePeriod * 1000;
    //console.log(`Expiry now ${new Date(this.expiry)}`)
  }
}
var _default = exports.default = InactivityTimer;
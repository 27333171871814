"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const scales = {
  yellow: {
    '50': '#fdfce9',
    '100': '#fcfac5',
    '200': '#fbf28d',
    '300': '#f7e240',
    '400': '#f3d21c',
    '500': '#e3b90f',
    '600': '#c4900a',
    '700': '#9c670c',
    '800': '#815212',
    '900': '#6e4315',
    '950': '#402208'
  },
  green: {
    // H=16 S=77 L=57
    '50': '#f0feef',
    '100': '#ddfeda',
    '200': '#bdfab8',
    '300': '#88f580',
    '400': '#48e63d',
    '500': '#24ce19',
    '600': '#18ab0e',
    '700': '#16860f',
    '800': '#156a11',
    '900': '#135710',
    '950': '#033003'
  },
  blue: {
    // 'azure-radiance' H=221 S=97 L=65
    '50': '#eff4ff',
    '100': '#dae5ff',
    '200': '#bed3ff',
    '300': '#91b7ff',
    '400': '#4f86fc',
    '500': '#3868f9',
    '600': '#2247ee',
    '700': '#1a34db',
    '800': '#1c2cb1',
    '900': '#1c2a8c',
    '950': '#161d55'
  },
  turquoise: {
    '50': '#ecfeff',
    '100': '#cefbff',
    '200': '#a3f5fe',
    '300': '#40e6fc',
    '400': '#1dd7f3',
    '500': '#01bad9',
    '600': '#0494b6',
    '700': '#0b7693',
    '800': '#135f77',
    '900': '#144f65',
    '950': '#073445'
  },
  sanMarino: {
    // H-220 S=34 L-50
    '50': '#f3f8fb',
    '100': '#e5ecf4',
    '200': '#d0dfed',
    '300': '#b0cae0',
    '400': '#8baecf',
    '500': '#6f94c2',
    '600': '#5c7db4',
    '700': '#5471ab',
    '800': '#465a87',
    '900': '#3c4b6c',
    '950': '#283043'
  },
  mercury: {
    '50': '#f7f7f7',
    '100': '#e6e6e6',
    '200': '#dfdfdf',
    '300': '#c8c8c8',
    '400': '#adadad',
    '500': '#999999',
    '600': '#888888',
    '700': '#7b7b7b',
    '800': '#676767',
    '900': '#545454',
    '950': '#363636'
  },
  'opal': {
    '50': '#f3f8f7',
    '100': '#e1ecec',
    '200': '#c6dbdb',
    '300': '#a7c8c8',
    '400': '#6fa1a1',
    '500': '#538587',
    '600': '#486f72',
    '700': '#3f5c5f',
    '800': '#394e51',
    '900': '#334346',
    '950': '#1f2b2d'
  },
  'genoa': {
    '50': '#f0fdfc',
    '100': '#ccfbf7',
    '200': '#99f6ef',
    '300': '#5eeadf',
    '400': '#2dd4c7',
    '500': '#14b8ab',
    '600': '#0d948a',
    '700': '#0f766e',
    '800': '#115e58',
    '900': '#134e49',
    '950': '#042f2c'
  },
  'onahau': {
    '50': '#f0faff',
    '100': '#e0f4fe',
    '200': '#caf0fe',
    '300': '#7cddfd',
    '400': '#36cdfa',
    '500': '#0cb7eb',
    '600': '#0094c9',
    '700': '#0175a3',
    '800': '#066286',
    '900': '#0b516f',
    '950': '#07344a'
  }
};
const primaries = {
  orange: {
    lighter: '#f7e240',
    light: '#f3d21c',
    default: '#e3b90f',
    dark: '#c4900a',
    darker: '#9c670c'
  },
  pink: {
    lighter: '#e9d5ff',
    light: '#d8b4fe',
    default: '#c084fc',
    dark: '#a855f7',
    darker: '#9333ea'
  },
  gihClassic: {
    lighter: "#83D6EE",
    light: "#45C1CD",
    default: "#00D1DC",
    dark: "#01A2A9",
    darker: "#066659"
  }
};
function getTheme(colors) {
  const themes = {
    gihClassic: {
      dark: false,
      scale: scales.opal,
      primary: primaries.pink,
      alt: primaries.gihClassic,
      bgGradient: ['#38bdf880', '#fbbf2480']
    },
    gihNew: {
      dark: true,
      scale: colors.fuchsia,
      primary: primaries.orange,
      alt: primaries.gihClassic,
      bgGradient: [colors.gray[200], colors.gray[300]]
    },
    test1: {
      dark: true,
      scale: scales.sanMarino,
      primary: primaries.orange
    },
    test2: {
      dark: true,
      scale: scales.onahau,
      primary: primaries.orange
    }
  };
  return themes[process.env.REACT_APP_THEME_NAME];
}
function getThemeColours(colors) {
  const theme = getTheme(colors);
  const colours = theme.dark ? {
    bg: {
      default: theme.scale[900],
      secondary: theme.scale[800],
      tertiary: theme.scale[700],
      paper: theme.scale[200],
      navbar: theme.scale[950],
      canvas: theme.scale[700] + 'd0'
    },
    fg: {
      default: theme.scale[900],
      secondary: theme.scale[700],
      tertiary: colors.gray[500],
      navbar: theme.scale[300]
    },
    fgCard: {
      default: theme.scale[50],
      secondary: theme.scale[200],
      divider: theme.scale[600],
      border: theme.scale[950],
      primary: theme.primary.lighter
    },
    table: {
      border: theme.scale[400],
      bg: theme.scale[800],
      bgSectionRow: theme.scale[700],
      borderSectionRow: theme.scale[500],
      header: theme.scale[700] + 'C0',
      horizRule: theme.scale[700],
      horizRuleFinal: theme.scale[200]
    }
  } : {
    bg: {
      default: theme.scale[300],
      secondary: theme.scale[200],
      tertiary: theme.scale[100],
      paper: theme.scale[50],
      navbar: theme.scale[950],
      canvas: theme.scale[700] + 'd0'
    },
    fg: {
      default: theme.scale[900],
      secondary: theme.scale[700],
      tertiary: colors.gray[400],
      navbar: theme.scale[300]
    },
    fgCard: {
      default: colors.black,
      secondary: colors.gray[700],
      divider: theme.scale[700],
      border: theme.scale[800],
      primary: theme.primary.darker
    },
    table: {
      border: theme.scale[400],
      bg: theme.scale[100],
      bgSectionRow: theme.scale[200],
      borderSectionRow: theme.scale[400],
      header: theme.scale[300] + 'C0',
      horizRule: theme.scale[200],
      horizRuleFinal: theme.scale[300]
    }
  };
  return _objectSpread(_objectSpread({}, colours), {}, {
    primary: theme.primary,
    alt: theme.alt
  });
}
function getTailwindConfig(colors) {
  var _theme$bgGradient$, _theme$bgGradient, _theme$bgGradient$2, _theme$bgGradient2;
  const themeColours = getThemeColours(colors);
  const theme = getTheme(colors);
  return {
    content: ["./src/**/*.{js,jsx,ts,tsx}", "./node_modules/gih_web_common/**/*.{js,jsx,ts,tsx}"],
    safelist: [{
      pattern: /text-(gray|amber|red|green|stone)-(400|500|600)/,
      variants: []
    }, {
      pattern: /bg-(gray|amber|red|green|stone|lime|cyan)-(300|400|500|600|700)/,
      variants: ['hover']
    }, {
      pattern: /border-(gray|amber|red|green|stone)-(400|500|600)/,
      variants: []
    }],
    theme: {
      fontFamily: {
        sans: 'Montserrat, "Helvetica Neue", Arial, Helvetica, sans-serif'
      },
      extend: {
        colors: themeColours
      },
      variables: {
        DEFAULT: {
          primaryLighter: themeColours.primary.lighter,
          primaryLight: themeColours.primary.light,
          primary: themeColours.primary.default,
          primaryDark: themeColours.primary.dark,
          primaryDarker: themeColours.primary.darker,
          appTheme: '#05483f',
          bgGradientStart: (_theme$bgGradient$ = (_theme$bgGradient = theme.bgGradient) === null || _theme$bgGradient === void 0 ? void 0 : _theme$bgGradient[0]) !== null && _theme$bgGradient$ !== void 0 ? _theme$bgGradient$ : theme.dark ? theme.scale[500] : theme.scale[200],
          bgGradientEnd: (_theme$bgGradient$2 = (_theme$bgGradient2 = theme.bgGradient) === null || _theme$bgGradient2 === void 0 ? void 0 : _theme$bgGradient2[1]) !== null && _theme$bgGradient$2 !== void 0 ? _theme$bgGradient$2 : theme.dark ? theme.scale[800] : theme.scale[500]
        }
      }
    }
  };
}
module.exports = {
  getTailwindConfig,
  getThemeColours
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.geohashToPlace = geohashToPlace;
exports.getPlaceDetails = getPlaceDetails;
exports.getPlacePredictions = getPlacePredictions;
require("core-js/modules/es.promise.js");
var _latlonGeohash = _interopRequireDefault(require("latlon-geohash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
let geocoder = null;
let autoCompleteService = null;
let placeDetailsResolver = null;
async function geohashToPlace(geohash, callback) {
  console.log('Resolving geohash to a place name');
  const coords = _latlonGeohash.default.decode(geohash);
  const google = window["google"];
  if (!geocoder) {
    const {
      Geocoder
    } = await google.maps.importLibrary("geocoding");
    geocoder = new Geocoder();
  }
  geocoder.geocode({
    'latLng': new google.maps.LatLng(coords.lat, coords.lon)
  }, (results, status) => {
    callback(status !== google.maps.GeocoderStatus.OK ? "(place not found - ".concat(status, ")") : results[0].formatted_address);
  });
}
async function getPlacePredictions(query, callback) {
  const google = window["google"];
  if (!autoCompleteService) {
    const {
      AutocompleteService
    } = await google.maps.importLibrary("places");
    autoCompleteService = new AutocompleteService();
  }
  autoCompleteService.getPlacePredictions(query, callback);
}
async function getPlaceDetails(placeId, callback) {
  const google = window["google"];
  if (!placeDetailsResolver) {
    const {
      PlacesService
    } = await google.maps.importLibrary("places");
    placeDetailsResolver = new PlacesService(document.createElement("div"));
  }
  placeDetailsResolver.getDetails({
    placeId: placeId
  }, callback);
}
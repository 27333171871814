"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateForFilename = dateForFilename;
exports.dateToString = dateToString;
exports.dateToStringNoTime = dateToStringNoTime;
exports.dateToStringOnlyTime = dateToStringOnlyTime;
exports.dateToStringRelative = dateToStringRelative;
exports.fmtChatTimestamp = fmtChatTimestamp;
exports.intervalToString = intervalToString;
exports.monthEnd = monthEnd;
exports.monthStart = monthStart;
exports.reinterpretAsLocal = reinterpretAsLocal;
exports.reinterpretAsUTC = reinterpretAsUTC;
require("core-js/modules/web.dom-collections.iterator.js");
var _luxon = require("luxon");
function dateToString(date) {
  let millisecond = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (date !== null) {
    const d = new Date(date);
    const options = millisecond ? {
      weekday: "short",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      fractionalSecondDigits: 3
    } : {
      dateStyle: "full",
      timeStyle: "short"
    };
    const locale = {
      localeMatcher: "lookup"
    };
    return d.toLocaleString(locale, options);
  } else {
    return '-';
  }
}
function asDateTime(d) {
  return d instanceof _luxon.DateTime ? d : _luxon.DateTime.fromJSDate(new Date(d));
}
function monthStart(when) {
  return asDateTime(when).startOf('month').toJSDate();
}
function monthEnd(when) {
  return asDateTime(when).endOf('month').toJSDate();
}
function intervalToString(start, end) {
  const start_ = asDateTime(start);
  const end_ = asDateTime(end);
  const duration = end_.diff(start_);
  let units = ['minutes'];
  if (duration.as('days') >= 1) {
    units = ['days'];
  } else if (duration.as('hours') >= 1) {
    units = ['hours'];
  }
  return duration.shiftTo(...units).toHuman({
    unitDisplay: "short",
    maximumFractionDigits: 0
  });
}
function dateToStringNoTime(date) {
  let style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'full';
  const d = new Date(date);
  const options = style === 'monthYear' ? {
    month: 'short',
    year: 'numeric'
  } : {
    dateStyle: style
  };
  return d.toLocaleString({
    localeMatcher: "lookup"
  }, options);
}
function dateForFilename(date) {
  const d = new Date(date);
  return "".concat(d.getFullYear(), "_").concat(d.getMonth() + 1, "_").concat(d.getDate());
}
function dateToStringOnlyTime(date) {
  const d = new Date(date);
  const options = {
    timeStyle: "short"
  };
  return d.toLocaleString({
    localeMatcher: "lookup"
  }, options);
}
function fmtChatTimestamp(when_) {
  const when = _luxon.DateTime.fromJSDate(when_);
  if (when < _luxon.DateTime.now().minus({
    days: 7
  })) {
    return when.toFormat('dd LLL');
  } else {
    return when.toRelative({
      style: "short"
    });
  }
}
function dateToStringRelative(when_) {
  const when = _luxon.DateTime.fromJSDate(new Date(when_));
  return when.toRelative();
}
function reinterpretAsUTC(localDate) {
  return localDate !== null ? _luxon.DateTime.fromJSDate(localDate).toUTC(0, {
    keepLocalTime: true
  }).toJSDate() : null;
}
function reinterpretAsLocal(utcDate) {
  return utcDate !== null ? _luxon.DateTime.fromJSDate(utcDate, {
    zone: 'utc'
  }).setZone('local', {
    keepLocalTime: true
  }).toJSDate() : null;
}
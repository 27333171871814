"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formError = formError;
exports.getSubText = getSubText;
function formError(what) {
  return "Please ".concat(what, " and then click the button again.");
}
function getSubText(length, maxLength, subText) {
  if (maxLength && subText) {
    return "".concat(length, "/").concat(maxLength, " (").concat(subText, ")");
  } else if (maxLength) {
    return "".concat(length, "/").concat(maxLength);
  } else {
    return subText;
  }
}
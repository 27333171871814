"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.monetaryAmountToString = monetaryAmountToString;
function monetaryAmountToString(amount) {
  let alwaysShowDP = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let currency = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GBP';
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: alwaysShowDP || amount % 100 !== 0 ? 2 : 0
  });
  return formatter.format(amount / 100);
}
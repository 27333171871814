"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.theme = exports.linkStyles = exports.inputStyles = exports.cellStyles = exports.buttonStyles = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const colors = require('tailwindcss/colors');
const {
  getThemeColours
} = require('./tailwind');
const twTheme = getThemeColours(colors);
const theme = exports.theme = {
  card: {
    bg: translucent => translucent ? {
      primary: {
        backgroundColor: twTheme.bg.default + 'cc',
        backdropFilter: 'blur(12px)'
      },
      secondary: {
        backgroundColor: twTheme.bg.secondary + 'cc'
      },
      tertiary: {
        backgroundColor: twTheme.bg.tertiary + 'cc'
      }
    } : {
      primary: {
        backgroundColor: twTheme.bg.default
      },
      secondary: {
        backgroundColor: twTheme.bg.secondary
      },
      tertiary: {
        backgroundColor: twTheme.bg.tertiary
      }
    }
  },
  modal: {
    overlay: {
      backgroundColor: twTheme.bg.canvas
    }
  },
  table: {
    border: {
      borderWidth: '2px',
      borderColor: twTheme.table.border
    },
    bg: {
      backgroundColor: twTheme.table.bg
    },
    sectionRow: {
      backgroundColor: twTheme.table.bgSectionRow,
      borderTopColor: twTheme.table.borderSectionRow,
      borderBottomColor: twTheme.table.horizRule
    },
    header: {
      backgroundColor: twTheme.table.header
    },
    horizRule: {
      borderBottomColor: twTheme.table.horizRule,
      borderBottomWidth: '2px'
    },
    horizRuleFinal: {
      borderTopWidth: '3px',
      borderTopColor: twTheme.table.horizRuleFinal
    }
  },
  svg: {
    fill: twTheme.fgCard.primary
  },
  primary: twTheme.primary
};
const baseColour = "text-white disabled:bg-gray-300 disabled:text-black disabled:border disabled:border-2 disabled:border-gray-400";
const button = {
  // Base style.
  base: "flex items-center justify-center whitespace-nowrap px-2 sm:px-3 py-2 font-bold text-sm sm:text-base rounded-xl",
  bigBase: "flex items-center justify-center px-4 py-4 font-bold text-lg sm:text-xl rounded-xl drop-shadow-lg",
  roundBase: "flex flex-row h-[36px] items-center py-1 px-2 rounded-full",
  tabBase: "whitespace-nowrap min-w-[8em] rounded-lg py-1 px-3 text-xs lg:text-sm 2xl:text-base font-medium transition duration-300",
  navBase: "flex items-center space-x-1 whitespace-nowrap px-3 py-1 rounded-lg text-lg transition duration-300",
  navBasePortal: "px-2 py-1 rounded-lg text-xs lg:text-sm font-medium text-center transition duration-300",
  // Focus.
  focus: "focus:outline-none focus:ring focus:ring-primary-default",
  altFocus: "focus:outline-none focus:ring focus:ring-alt-default",
  // Heights.
  large: "min-h-[40px]",
  // Widths.
  narrow: "mx-auto w-fit max-w-[30em]",
  wide: "mx-auto w-full min-w-[10em] max-w-[30em]",
  vwide: "mx-auto w-full sm:min-w-[30em] max-w-[30em]",
  // Colours.
  alt: "bg-alt-dark hover:bg-alt-darker " + baseColour,
  primary: "bg-primary-dark hover:bg-primary-darker " + baseColour,
  blue: "bg-blue-500 hover:bg-blue-700 " + baseColour,
  amber: "bg-amber-500 hover:bg-amber-700 " + baseColour,
  red: "bg-red-500 hover:bg-red-700 " + baseColour,
  emerald: "bg-emerald-500 hover:bg-emerald-700 " + baseColour,
  green: "bg-green-500 hover:bg-green-700 " + baseColour,
  black: "bg-black hover:bg-gray-500 " + baseColour,
  outline: "bg-white text-black hover:bg-gray-500 disabled:text-gray-300 hover:text-white border border-black disabled:border-gray-300",
  primaryOutline: "text-fgCard-primary hover:bg-primary-darker hover:text-white hover:border hover:border-white"
};
const buttonStyles = exports.buttonStyles = {
  alt: button.base + " " + button.altFocus + " " + button.alt + " " + button.narrow,
  altLg: button.base + " " + button.altFocus + " " + button.alt + " " + button.large + " " + button.wide,
  altLgNarrow: button.base + " " + button.altFocus + " " + button.alt + " " + button.large + " " + button.narrow,
  primaryRound: button.roundBase + " " + button.focus + " " + button.primary,
  primaryRoundOutline: button.roundBase + " " + button.focus + " " + button.primaryOutline,
  primaryLg: button.base + " " + button.focus + " " + button.primary + " " + button.large + " " + button.wide,
  primaryLgNarrow: button.base + " " + button.focus + " " + button.primary + " " + button.large + " " + button.narrow,
  primaryLgWide: button.base + " " + button.focus + " " + button.primary + " " + button.large + " " + button.vwide,
  blackLg: button.base + " " + button.focus + " " + button.black + " " + button.large + " " + button.wide,
  blackLgNarrow: button.base + " " + button.focus + " " + button.black + " " + button.large + " " + button.narrow,
  blueLg: button.base + " " + button.focus + " " + button.blue + " " + button.large + " " + button.wide,
  blueLgNarrow: button.base + " " + button.focus + " " + button.blue + " " + button.large + " " + button.narrow,
  amberLg: button.base + " " + button.focus + " " + button.amber + " " + button.large + " " + button.wide,
  amberLgNarrow: button.base + " " + button.focus + " " + button.amber + " " + button.large + " " + button.narrow,
  redLg: button.base + " " + button.focus + " " + button.red + " " + button.large + " " + button.wide,
  redLgNarrow: button.base + " " + button.focus + " " + button.red + " " + button.large + " " + button.narrow,
  emeraldLg: button.base + " " + button.focus + " " + button.emerald + " " + button.large + " " + button.wide,
  emeraldLgNarrow: button.base + " " + button.focus + " " + button.emerald + " " + button.large + " " + button.narrow,
  outlineLgNarrow: button.base + " " + button.focus + " " + button.outline + " " + button.large + " " + button.narrow,
  bigGreen: "mx-auto w-fit " + button.bigBase + " " + button.altFocus + " " + button.green,
  menu: "inline-flex items-center justify-center whitespace-nowrap px-2 sm:px-3 py-2 border border-gray-300 shadow-sm min-h-[40px]",
  navSelected: button.navBase + " bg-primary-dark text-white " + button.focus,
  navUnselected: button.navBase + " text-primary-default border border-primary-default hover:bg-primary-darker hover:border-white hover:text-white " + button.focus,
  navSelectedPortal: button.navBasePortal + " bg-primary-dark border border-2 border-white text-white " + button.focus,
  navUnselectedPortal: button.navBasePortal + " text-primary-default hover:bg-primary-darker hover:text-white " + button.focus,
  altNavSelectedPortal: button.navBasePortal + " bg-alt-dark border border-2 border-white text-white " + button.altFocus,
  altNavUnselectedPortal: button.navBasePortal + " text-alt-default hover:bg-alt-darker hover:text-white " + button.altFocus,
  tabSelected: button.tabBase + " bg-primary-dark text-white " + button.focus,
  tabUnsleected: button.tabBase + " bg-white text-primary-dark border border-primary-dark hover:bg-primary-darker hover:text-white " + button.focus,
  altTabSelected: button.tabBase + " bg-alt-dark text-white " + button.altFocus,
  altTabUnsleected: button.tabBase + " bg-white text-alt-dark border border-alt-dark hover:bg-alt-darker hover:text-white " + button.altFocus
};
const input = {
  base: "appearance-none px-3 py-2 bg-bg-paper",
  sizeM: "text-xs sm:text-base",
  sizeL: "text-lg sm:text-3xl font-bold placeholder:font-normal placeholder:text-lg",
  sizeXL: "text-2xl sm:text-4xl font-bold placeholder:font-normal placeholder:text-2xl",
  normal: "border border-fg-secondary text-fg-default placeholder:text-fg-tertiary placeholder:italic focus:outline-none focus:ring focus:ring-primary-default focus:border-primary-default",
  error: "border border-red-400 text-fg-default border-4 focus:border-red-400 focus:outline-none focus:ring focus:ring-red-400",
  select: "max-w-full bg-bg-paper text-sm sm:text-base border-fg-secondary focus:ring focus:ring-primary-default focus:border-primary-default rounded-md"
};
const inputStyles = exports.inputStyles = _objectSpread(_objectSpread({}, input), {}, {
  text: input.base + " " + input.sizeM + " " + input.normal + " rounded-md",
  textL: input.base + " " + input.sizeL + " " + input.normal + " rounded-md",
  textXL: input.base + " " + input.sizeXL + " " + input.normal + " rounded-md",
  select: input.select + " text-fg-default",
  selectPlaceholder: input.select + " text-fg-tertiary italic",
  range: "accent-primary-default",
  checkbox: "cursor-pointer bg-bg-paper text-primary-default focus:outline-none focus:ring focus:ring-primary-default focus:ring-offset-1"
});
const linkStyles = exports.linkStyles = {
  image: "focus:outline-none focus:ring focus:ring-primary-default",
  inline: "text-fgCard-primary hover:underline hover:text-primary-darker hover:cursor-pointer focus:outline-none focus:ring focus:ring-primary-default"
};
const cellStyles = exports.cellStyles = {
  date: {
    minWidth: 120,
    width: 160,
    textAlign: "left"
  },
  dateTime: {
    minWidth: 160,
    width: 200,
    textAlign: "left"
  },
  dateShort: {
    minWidth: 120,
    width: 120,
    textAlign: "left"
  },
  relativeTime: {
    minWidth: 160,
    width: 160,
    textAlign: "center"
  },
  personAvatar: {
    minWidth: 260,
    width: 260,
    textAlign: "center"
  },
  person: {
    minWidth: 120,
    width: 200,
    textAlign: "left"
  },
  charity: {
    minWidth: 360,
    width: 360,
    textAlign: "center"
  },
  email: {
    minWidth: 200,
    width: 200,
    textAlign: "center"
  },
  address: {
    minWidth: 220,
    width: 220,
    textAlign: "center"
  },
  actions: {
    minWidth: 120,
    width: 240,
    textAlign: "center"
  },
  money: {
    minWidth: 160,
    width: 160,
    textAlign: "right"
  },
  freeWide: {
    minWidth: 400,
    textAlign: "left"
  },
  freeMedium: {
    minWidth: 200,
    textAlign: "left"
  },
  freeNarrow: {
    minWidth: 140,
    textAlign: "left"
  },
  limitedMedium: {
    minWidth: 140,
    width: 260,
    textAlign: "left"
  },
  fixedWide: {
    minWidth: 300,
    width: 300,
    textAlign: "center"
  },
  fixedMedium: {
    minWidth: 200,
    width: 200,
    textAlign: "center"
  },
  fixedNarrow: {
    minWidth: 140,
    width: 140,
    textAlign: "center"
  },
  icon: {
    minWidth: 120,
    width: 120,
    textAlign: "center"
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.progressAnnotation = progressAnnotation;
var _currency = require("../utils/currency");
var _misc = require("../utils/misc");
function progressAnnotation(total, target, supporters) {
  if (total > 0) {
    return "".concat((0, _currency.monetaryAmountToString)(total), " raised out of ").concat((0, _currency.monetaryAmountToString)(target), " target by ").concat((0, _misc.countToString)('supporter', supporters));
  } else {
    return "Awaiting first donation towards ".concat((0, _currency.monetaryAmountToString)(target), " target");
  }
}
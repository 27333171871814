"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authErrorDecoder = authErrorDecoder;
exports.canonicalisePhoneNumber = canonicalisePhoneNumber;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.string.trim.js");
function authErrorDecoder(e) {
  switch (e.code) {
    case "auth/user-not-found":
    case "auth/email-not-found":
      return {
        email: "Couldn't find your account."
      };
    case "auth/wrong-password":
      return {
        password: "That's an incorrect password. Try again."
      };
    case "auth/invalid-email":
      return {
        email: "Please enter a valid e-mail address."
      };
    case "auth/invalid-phone-number":
      return {
        phone: "Please enter a valid mobile number."
      };
    case "auth/too-many-requests":
      return "Access to this account is temporarily disabled due to too many failed attempts.";
    case "auth/email-already-in-use":
      return {
        email: "Account already exists."
      };
    case "auth/weak-password":
      return {
        password: "Password must contain 6+ characters, a symbol & a number."
      };
    default:
      return "Something went wrong on our side: ".concat(e);
  }
}
function canonicalisePhoneNumber(phone) {
  const s = phone.trim();
  if (!/(\+44|0)[0-9]{10}/.test(s)) return null;else if (s.substr(0, 1) === '0') return "+44".concat(s.substr(1));else return s;
}